import { useRequest } from 'ahooks';
import {
  Button,
  Form,
  Input,
  Modal,
  Picker,
  Popup,
  Radio,
  Skeleton,
  TextArea,
  Toast,
} from 'antd-mobile';
import { EditSOutline, RightOutline } from 'antd-mobile-icons';
import { useEffect, useState } from "react";
import { getUserAddressService, postAddressPublishService } from "../services";
import { useAddressBookStore } from '../stores/addressBook';
import { useRegionsStore } from '../stores/regions';
import LoadingSkeleton from './LoadingSkeleton';

function AddOrEditAddressSection({
  onClose,
  onSuccess,
  editId,
}: {
  onClose?: () => void;
  onSuccess?: (payload?: any) => void;
  editId?: number; // 填写即为修改模式
}) {
  const regions = useRegionsStore((state) => state.regions);
  const [regionPickerVisible, setRegionPickerVisible] =
    useState<boolean>(false);

  const { addresses: userAddresses } = useAddressBookStore();
  const [form] = Form.useForm();
  const { run: postAddress, loading } = useRequest(postAddressPublishService, {
    manual: true,
    onSuccess({ status, msg, data }) {
      if (status !== 200) {
        Toast.show({
          icon: "fail",
          content: msg,
        });
        return;
      }
      form.resetFields();
      onSuccess?.(data.id);
    },
  });

  useEffect(() => {
    form.resetFields();
    if (editId) {
      const address = userAddresses.find((a) => a.id === editId);
      form.setFieldsValue({
        ...address,
        region_id: [`${address?.region_id || 0}`],
      });
    }
  }, [editId, form, userAddresses]);

  const tgOrPhoneValidator = (_: any, _value: string) => {
    if (form.getFieldValue("tg") || form.getFieldValue("phone"))
      return Promise.resolve();
    return Promise.reject(new Error("请填写 TG 账号或手机号"));
  };
  return (
    <Form
      form={form}
      onFinishFailed={({ errorFields }) =>
        Toast.show(errorFields?.[0].errors[0])
      }
      onFinish={(values: any) => {
        postAddress({
          ...values,
          region_id: +values.region_id[0],
          id: editId && editId > 0 ? editId : undefined,
        });
      }}
      layout="horizontal"
      style={{
        "--border-top": "none",
        "--border-bottom": "none",
      }}
    >
      <Form.Header> </Form.Header>
      <Form.Item
        name="name"
        label="联系人"
        rules={[{ required: true, message: "姓名不能为空" }]}
      >
        <Input placeholder="请输入英文名" />
      </Form.Item>
      <Form.Item
        name="tg"
        label="TG 账号"
        initialValue=""
        rules={[
          {
            validator: tgOrPhoneValidator,
          },
        ]}
      >
        <Input placeholder="请输入 TG 账号" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="手机号"
        initialValue=""
        rules={[
          {
            validator: tgOrPhoneValidator,
          },
        ]}
      >
        <Input placeholder="请输入手机号" />
      </Form.Item>
      <Form.Item
        name="region_id"
        label="所在地区"
        trigger="onConfirm"
        rules={[{ required: true, message: "所在地区不能为空" }]}
        onClick={() => setRegionPickerVisible(true)}
      >
        <Picker
          columns={[
            [
              ...regions.map((r) => ({ label: r.name, value: `${r.id}` })),
              { label: "其它地区", value: "0" },
            ],
          ]}
          className="z-1001"
          visible={regionPickerVisible}
          onClose={() => {
            setRegionPickerVisible(false);
          }}
        >
          {([value]) => {
            return (
              <Input readOnly value={value ? (value.label as any) : "请选择"} />
            );
          }}
        </Picker>
      </Form.Item>

      <Form.Item
        name="address"
        label="详细地址"
        rules={[{ required: true, message: "详细地址不能为空" }]}
      >
        <TextArea placeholder="小区楼栋 / 乡村名称" maxLength={100} rows={2} />
      </Form.Item>
      <p className="text-center mt-2 px-4">
        {userAddresses.length > 0 ? (
          <Button
            type="button"
            block
            color="primary"
            fill="outline"
            onClick={() => {
              form.resetFields();
              onClose?.();
            }}
            className="text-blue-600 text-sm"
          >
            返回
          </Button>
        ) : (
          <Button
            type="submit"
            block
            color="primary"
            fill="outline"
            loading={loading}
            className="text-blue-600 text-sm"
          >
            添加地址
          </Button>
        )}
      </p>
      {userAddresses.length > 0 && (
        <div className="absolute bottom-0 w-full left-0 p-2 pb-8 shadow-lg">
          <Button
            shape="rounded"
            className="font-bold text-lg px-6"
            type="submit"
            color="primary"
            style={{
              height: "46px",
            }}
            block
            loading={loading}
          >
            确认
          </Button>
        </div>
      )}
    </Form>
  );
}

function AddressBook() {
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  // Radio.Group 控制
  const [addressId, setAddressId] = useState<number>(1);
  const [addressOnEditId, setAddressOnEditId] = useState<number>(0);

  const regions = useRegionsStore((state) => state.regions);
  const {
    selectedAddressId,
    setSelectedAddressId,
    addresses: userAddresses,
    setAddresses,
  } = useAddressBookStore();

  const { loading, refresh } = useRequest(getUserAddressService, {
    onSuccess({ status, msg, data }) {
      if (status !== 200) {
        Toast.show({
          icon: "fail",
          content: msg,
        });
        return;
      }
      if (
        data.length > 0 &&
        !data.map(({ id }) => id).includes(selectedAddressId)
      ) {
        setSelectedAddressId(data[0].id);
      }
      setAddressId(selectedAddressId);
      setAddresses(data);
    },
  });

  if (loading) return <LoadingSkeleton />;

  const selectedAddress = userAddresses.find((a) => a.id === selectedAddressId);

  return (
    <>
      <section className="px-4 space-y-4 py-4 bg-white">
        {userAddresses && userAddresses?.length > 0 ? (
          <div className="flex justify-between text-xs">
            <div className="leading-6">
              <h3 className="font-bold text-lg mb-1">
                {regions.find((r) => selectedAddress?.region_id === r.id)
                  ?.name || "其它地区"}
              </h3>
              <h3 className="font-bold text-lg">{selectedAddress?.address}</h3>
              <p className="space-x-4 mt-2">
                <span>{selectedAddress?.name}</span>{" "}
                {selectedAddress?.phone && (
                  <span>手机号：{selectedAddress?.phone}</span>
                )}
                {selectedAddress?.tg && <span>TG：{selectedAddress.tg}</span>}
              </p>
            </div>
            <RightOutline
              onClick={() => setPopupVisible(true)}
              width={20}
              height={20}
              className="text-gray-500"
            />
          </div>
        ) : (
          <AddOrEditAddressSection
            onClose={() => {
              setAddressOnEditId(0);
              setFormVisible(false);
            }}
            onSuccess={(payload) => {
              refresh();
              setSelectedAddressId(payload);
              setFormVisible(false);
            }}
          />
        )}
      </section>
      {popupVisible && (
        <Popup
          visible={popupVisible}
          onMaskClick={() => setPopupVisible(false)}
          className="sku-detail"
          bodyStyle={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            minHeight: "80vh",
            padding: "1rem",
          }}
        >
          <h3 className="font-bold text-lg text-center mb-2">
            {formVisible ? "新增地址" : "选择收货地址"}
          </h3>
          {formVisible ? (
            <AddOrEditAddressSection
              onClose={() => setFormVisible(false)}
              editId={addressOnEditId}
              onSuccess={(payload) => {
                setFormVisible(false);
                setPopupVisible(false);
                refresh();
                setSelectedAddressId(payload);
              }}
            />
          ) : (
            <>
              <div className="w-full">
                <Radio.Group
                  value={addressId}
                  onChange={(e) => setAddressId(+e)}
                >
                  {userAddresses?.map(
                    ({ id, name, address, region_id, phone, tg }) => (
                      <Radio
                        key={id}
                        value={id}
                        className="w-full border-b-gray-200 border-b"
                        block
                      >
                        <div className="w-full flex justify-between items-center py-4">
                          <div>
                            <h4 className="text-sm">
                              {regions.find((r) => r.id === +region_id)?.name ||
                                "其它地区"}{" "}
                              {address}
                            </h4>
                            <p className="space-x-4 text-xs text-gray-600">
                              <span>{name}</span>
                              {phone && (
                                <span>
                                  手机号：
                                  <a
                                    target="_blank"
                                    href={`tel:${phone}`}
                                    rel="noreferrer"
                                  >
                                    {phone}
                                  </a>
                                </span>
                              )}
                              {tg && (
                                <span>
                                  Telegram：
                                  <a
                                    target="_blank"
                                    href={`https://t.me/${tg}`}
                                    rel="noreferrer"
                                  >
                                    {tg}
                                  </a>
                                </span>
                              )}
                            </p>
                          </div>
                          <EditSOutline
                            onClick={() => {
                              setAddressOnEditId(id);
                              setFormVisible(true);
                            }}
                            width={14}
                            height={14}
                          />
                        </div>
                      </Radio>
                    )
                  )}
                </Radio.Group>
                <p className="text-center mt-2">
                  <Button
                    type="button"
                    block
                    color="primary"
                    fill="outline"
                    onClick={() => {
                      setAddressOnEditId(0);
                      setFormVisible(true);
                    }}
                    className="text-sm"
                  >
                    添加地址 +
                  </Button>
                </p>
              </div>

              <div className="absolute bottom-0 w-full left-0 p-2 pb-8 shadow-lg">
                <Button
                  shape="rounded"
                  className="font-bold text-lg px-6"
                  color="primary"
                  style={{
                    height: "46px",
                  }}
                  block
                  onClick={() => {
                    setSelectedAddressId(addressId);
                    setPopupVisible(false);
                  }}
                >
                  确认
                </Button>
              </div>
            </>
          )}
        </Popup>
      )}
    </>
  );
}

export default AddressBook;
