import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Form,
  Modal,
  NavBar,
  Skeleton,
  TextArea,
  Toast,
} from "antd-mobile";
import FooterBar from "../components/FooterBar";
import { useCartStore } from "../stores/cart";
import { uniqBy } from "lodash";
import SkuCartCard from "../components/SkuCartCard";
import { useProductsStore } from "../stores/products";
import { useNavigate } from "react-router-dom";
import { useRegionsStore } from "../stores/regions";
import { useAddressBookStore } from "../stores/addressBook";
import AddressBook from "../components/AddressBook";
import { useRequest } from "ahooks";
import { postOrderPublishService } from "../services";
import LoadingSkeleton from "../components/LoadingSkeleton";
import HeaderBar from "../components/HeaderBar";
import { useCurrentUserStore } from "../stores/user";
import { useStoreStore } from "../stores/store";

function Checkout() {
  const { productsCart } = useProductsStore();
  const { store } = useStoreStore();
  const { cart, getCartSubTotal, setCart,getCartPreTotal } = useCartStore();
  const subtotal = useMemo(
    () => getCartSubTotal(productsCart).toFixed(2),
    [getCartSubTotal, productsCart]
  );
  const preferential = useMemo(
    () => getCartPreTotal(productsCart).toFixed(2),
    [getCartSubTotal, productsCart]
  );
  
  const { regions } = useRegionsStore();
  const { addresses: userAddresses, selectedAddressId } = useAddressBookStore();
  const { refreshUserAsync } = useCurrentUserStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const minimumCharge = +(store?.extra.minimum_charge || 0);
  const { deliveryFee, oldDeliveryFee, startPrice } = useMemo(() => {
    const selectedAddress = userAddresses.find(
      (a) => a.id === selectedAddressId
    );
    const region = regions.find((r) => r.id === selectedAddress?.region_id);
    return {
      deliveryFee: region?.delivery_fee || 0,
      oldDeliveryFee: region?.old_delivery_fee || 0,
      startPrice: region?.start_price || minimumCharge,
    };
  }, [regions, selectedAddressId, userAddresses, minimumCharge]);

  const { run: makeOrder, loading } = useRequest(postOrderPublishService, {
    manual: true,
    onSuccess: async (e) => {
      if (e.status !== 200) {
        Toast.show({
          icon: "fail",
          content: e.msg,
        });
        return;
      }
      await refreshUserAsync();
      Toast.show({
        icon: "success",
        content: e.msg,
      });
      if (e.data.need_confirm) {
        Modal.show({
          title: "配送费确认",
          content: "请先与我们联系，确认配送地址和配送费",
          closeOnMaskClick: false,
          closeOnAction: true,
          actions: [
            {
              key: "ok",
              text: "知道了",
              primary: true,
              onClick: () => {
                if (store) {
                  window.open(
                    (store.extra.telegram
                      ? `https://t.me/${store.extra.telegram}`
                      : "") || `tel:${store.extra.phone}`
                  );
                }
              },
            },
          ],
        });
      }
      navigate("/");
      form.resetFields();
      setCart([]);
    },
  });

  const finalDeliveryFee = useMemo(() => {
    const selectedAddress = userAddresses.find(
      (a) => a.id === selectedAddressId
    );

    if (selectedAddressId > 0 && !selectedAddress?.region_id) return 999;
    return +subtotal >= startPrice ? deliveryFee : oldDeliveryFee;
  }, [
    subtotal,
    startPrice,
    deliveryFee,
    oldDeliveryFee,
    userAddresses,
    selectedAddressId,
  ]);
  const discount = store?.extra.discount || 100;
  // const discountFee = +subtotal * ((100 - discount) / 100);
  const discountFee = Number(subtotal) - Number(preferential);
  

  return (
    <div className="others">
      <HeaderBar>确认订单</HeaderBar>
      <div className="bg-gray-100 min-h-screen pb-24">
        <AddressBook />
        {productsCart.length === 0 ? (
          <LoadingSkeleton />
        ) : (
          <Form
            form={form}
            onFinish={(e) => {
              if (userAddresses.length === 0) {
                Toast.show({
                  icon: "fail",
                  content: "请确认添加地址",
                });
                return;
              }
              const selectedAddress = userAddresses.find(
                (a) => a.id === selectedAddressId
              );
              if (!selectedAddress) {
                Toast.show({
                  icon: "fail",
                  content: "请选择收货地址",
                });
                return;
              }
              makeOrder({
                ...e,
                ...selectedAddress,
                cart,
              });
            }}
            layout="vertical"
          >
            <Form.Header> </Form.Header>
            <section className="p-4 space-y-4">
              {uniqBy(cart, (c) => JSON.stringify(c)).map((c) => (
                <SkuCartCard
                  key={JSON.stringify(c)}
                  featured
                  smallImage
                  cartProduct={c}
                  stepper={(quantity) => (
                    <span className="text-2xs">x{quantity}</span>
                  )}
                />
              ))}
              <p className="text-right text-xs">
                <span>共 {cart.length} 件，</span>
                商品小计：
                <span className="text-red-600 font-bold">
                  <span className="text-2xs">P</span>
                  {subtotal}
                </span>
              </p>
			  {
				discountFee > 0 && (
					<p className="text-red-500 text-xs text-right font-bold">
					  已优惠 <span className="text-2xs">P</span>
					  {discountFee.toFixed(2)}
					</p>
				  )
			  }
              {
				  // discount < 100 && (
      //           <p className="text-red-500 text-xs text-right font-bold">
      //             已优惠 <span className="text-2xs">P</span>
      //             {discountFee.toFixed(2)}
      //           </p>
      //         )
			  }
            </section>
            <Form.Header> </Form.Header>
            <div className="cell px-4">
              <div>配送费用</div>
              <div className="text-xsm">
                {oldDeliveryFee !== finalDeliveryFee && (
                  <span className="text-gray-400 line-through">
                    P{oldDeliveryFee}
                  </span>
                )}
                <span className="font-bold">P{finalDeliveryFee}</span>
              </div>
            </div>
            <Form.Header> </Form.Header>
            <div className="cell flex-wrap px-4">
              <div className="mb-2">订单备注</div>
              <Form.Item noStyle label="订单备注" name="user_comment">
                <TextArea
                  rows={4}
                  className="bg-gray-100 w-full rounded p-2 mt-2 text-xs"
                  placeholder="请备注信息"
                />
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
      <FooterBar>
        <div className="bottom-bar-inner flex items-center justify-between">
          <div>
            <span className="text-xsm">合计：</span>
            <span className="text-red-600 font-bold">
              <span className="price-unit text-sm">P</span>
              <span className="text-lg">
                {(
                  Number(subtotal) +
                  Number(finalDeliveryFee) -
                  discountFee
                ).toFixed(2)}
              </span>
            </span>
          </div>
          <Button
            shape="rounded"
            onClick={() => form.submit()}
            loading={loading}
            className="font-bold text-lg px-6"
            color="primary"
            style={{
              height: "46px",
            }}
          >
            提交订单
          </Button>
        </div>
      </FooterBar>
    </div>
  );
}

export default Checkout;
