import React, { ChangeEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SideBar, Skeleton, Tabs } from "antd-mobile";
import { useThrottleFn, useRequest } from "ahooks";
import BottomBar from "../components/BottomBar";
import SkuCard from "../components/SkuCard";
import TopSlider from "../components/TopSlider";
import type { TaxonomyObject, TProduct } from "../react-app-env";
import { useProductsStore } from "../stores/products";
import { groupBy, uniqBy } from "lodash";
import GroupBuyButton from "../components/GroupBuyButton";
import { useStoreStore } from "../stores/store";
import { Button, Form, Input, Modal, Toast,Image} from 'antd-mobile';
import {
	getProductsService,
} from '../services';
import { useNavigate,useParams } from "react-router-dom";

type ProductsListProps = {
	loading?: boolean;
	products?: TProduct[];
};
function ProductsList({ loading = false, products }: ProductsListProps) {
	if (loading) {
		return (
			<>
				<Skeleton.Title />
				<Skeleton.Paragraph />
			</>
		);
	}
	return (
		<>
			{products?.map((product) => {
				return <SkuCard key={product.id} product={product} />;
			})}
		</>
	);
}

function Home() {
	const navigate = useNavigate();
	// const { searchkey } = useParams();
	// console.log('searchkey',searchkey)
	const products = useProductsStore((state) => state.products);
	
	// 顶部菜单
	const parentCats = useMemo(() => {
		if (!products.some((p) => p.parent_category)) return [];
		return uniqBy(
			products.map((p) => p.parent_category),
			(pc) => pc?.id
		)
			.filter((t) => typeof t === "object")
			.sort((a, b) => (a?.orderby || 0) - (b?.orderby || 0));
	}, [products]);
	const [activeParentCat, setActiveParentCat] = useState<string>("");
	useEffect(() => {
		if (!activeParentCat && parentCats.length > 0)
			setActiveParentCat(`${parentCats[0]?.id}`);
	}, [parentCats, activeParentCat]);

	const { store } = useStoreStore();
	const isMainNavEnabled = !(store?.extra.main_nav === "close");

	// 左侧二级菜单
	const [activeCat, setActiveCat] = useState<string>("");
	const cats = useMemo(() => {
		if (parentCats.length === 0)
			return uniqBy(
				products?.map((p) => p.category),
				(c) => c.id
			).sort((a, b) => (a?.orderby || 0) - (b?.orderby || 0));

		if (!isMainNavEnabled) {
			const mergedCats: TaxonomyObject[] = [];
			parentCats.forEach((pC) => {
				if (pC) {
					mergedCats.push(pC);
					mergedCats.push(
						...uniqBy(
							products
								?.filter((p) => p.parent_category?.id == pC.id)
								.map((p) => p.category),
							(c) => c.id
						).sort((a, b) => (a?.orderby || 0) - (b?.orderby || 0))
					);
				}
			});
			return uniqBy(mergedCats, (c) => c.id);
		}
		return uniqBy(
			products
				?.filter((p) => p.parent_category?.id === +activeParentCat)
				.map((p) => p.category),
			(c) => c.id
		).sort((a, b) => (a?.orderby || 0) - (b?.orderby || 0));
	}, [activeParentCat, products, parentCats, isMainNavEnabled]);
	useEffect(() => {
		if (cats.length > 0 && !cats.map(({ id }) => id).includes(+activeCat))
			setActiveCat(`${cats[0].id}`);
	}, [cats, activeCat]);
	// 经过筛选和排序的商品列表（groupBy）
	const groupedProductsList = useMemo(() => {
		const sortedProducts: TProduct[][] = [];
		const activeProducts = groupBy(
			products?.filter((p) =>
				!isMainNavEnabled || !activeParentCat
					? true
					: p.parent_category?.id === +activeParentCat
			),
			(p) => p.category.id
		);
		cats.forEach(({ id }) => sortedProducts.push(activeProducts[id]));
		return sortedProducts;
	}, [activeParentCat, cats, products, isMainNavEnabled]);
	
	// 侧边 tab 跟随
	const { run: handleScroll } = useThrottleFn(
		() => {
			if (cats.length === 0) return;
			let currentKey = cats[0].id;
			cats.forEach((cat) => {
				const element = document.getElementById(`anchor-${cat.id}`);
				if (!element) return;
				const rect = element.getBoundingClientRect();
				if (rect.top <= 0) {
					currentKey = cat.id;
				}
			});
			setActiveCat(`${currentKey}`);
		},
		{
			leading: true,
			trailing: true,
			wait: 100,
		}
	);
	useLayoutEffect(() => {
		window.addEventListener("scroll", handleScroll);
		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);
	const [searchButStatus,updateSearchButStatus] = useState(true);
	const [form] = Form.useForm();
	const setProducts = useProductsStore((state) => state.setProducts);
	const { runAsync: GetSearchData,loading } = useRequest((e)=>getProductsService({searchkey:e ? e.keytitle : ''}), {
	    // pollingInterval: 3000,
	    onSuccess({data, status, msg}) {
			const activeProducts = groupBy(
				data?.filter((p) =>
					!isMainNavEnabled || !activeParentCat
						? true
						: p.parent_category?.id === +activeParentCat
				),
				(p) => p.category.id
			);
	        setProducts(data);
	    },
	});
	const searchButText = useMemo(() => {
	  // return searchButStatus ? '搜索' : '取消';
	  return '搜索';
	}, [searchButStatus]);
	return (
		<div className="home pb-24">
			<TopSlider />
			<Form
				form={form}
				onFinish={(e) => {
					if (e.keytitle == '') {
						Toast.show("请输入商品名称");
						return;
					}
					 // updateSearchButStatus(!searchButStatus)
					 // if (searchButStatus == false) {
						//  e = {keytitle:''}
					 // }
					 GetSearchData(e);
				}}
				onFinishFailed={({ errorFields: [{ errors }] }) => {
					Toast.show(errors[0]);
				}}
				layout="horizontal"
			>
				<div className="flex">
					<Image className="gmy-home-index-img" onClick={() => {
						// GetSearchData({keytitle:''});
						window.location.reload();
					}} width={48} height={30} src='https://qiniuyun.dfcaiyao.com/wp-content/uploads/2023/02rc-upload-1677499429604-2index-fill.png?imageView2/0/interlace/1/q/20/format/jpg'/>
					<Form.Item noStyle label="商品名称" name="keytitle" required>
						<Input
							placeholder="输入商品名称"
							className="bg-gray-200 px-4 py-3 mr-2 gmy-home-input"
							type="text"
							clearable={true}
							onlyShowClearWhenFocus={false}
							onChange={(e) =>{
								if (e.length == 0) {
									GetSearchData({keytitle:''});
								}
							}}
						/>
					</Form.Item>
					<Button
						block
						type="submit"
						color="primary"
						loading={loading}
						className="px-6 gmy-home-but">
						{searchButText}
					</Button>
				</div>
			</Form>
			{parentCats.length > 0 && isMainNavEnabled && (
				<Tabs
					activeKey={activeParentCat}
					onChange={(key) => setActiveParentCat(key)}
					className="bg-white"
				>
					{parentCats.map(({ id, name }: any) => (
						<Tabs.Tab title={name} key={`${id}`} />
					))}
				</Tabs>
			)}
			<main className="home min-h-70vh bg-gray-200 flex justify-start">
				<SideBar
					activeKey={activeCat}
					onChange={(key) => {
						setActiveCat(key);
						document.getElementById(`anchor-${key}`)?.scrollIntoView();
					}}
					className="aside flex-none sticky top-1"
				>
					{(isMainNavEnabled ? cats : parentCats).map(({ id, name }: any) => (
						<SideBar.Item key={id} title={name} />
					))}
				</SideBar>
				<section className="content bg-white overflow-hidden pt-4 px-4 flex-auto">
					{store?.status === "open" && (
						<p className="text-right mb-2">
							<GroupBuyButton />
						</p>
					)}
					<div className="">
						{Object.entries(groupedProductsList).map(
							([catIndex, productsByCat]) => (
								<React.Fragment key={catIndex}>
									<h5
										className="text-sm font-bold mt-6 mb-2"
										id={`anchor-${cats[+catIndex].id}`}
									>
										{cats[+catIndex].name}
									</h5>
									<div className="product-list space-y-6">
										<ProductsList
											loading={!products}
											products={productsByCat}
										/>
									</div>
								</React.Fragment>
							)
						)}
					</div>
				</section>
			</main>
			<BottomBar />
		</div>
	);
}

export default Home;
